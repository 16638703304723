import { PaymentType } from '../payment-type-enum'

export enum PrepaymentDirection {
  JAVA = 'JAVA',
  MQA = 'MQA',
  GO = 'GO',
  FE = 'FE',
}

export interface UserRegistrationDto {
  email: string
  firstName: string
  lastName: string
  password: string
  paymentType: PaymentType
  direction: PrepaymentDirection
}
